html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root {
  height: 100%;
}

#map {
  user-select: none;
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}
